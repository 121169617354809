<template>
  <v-card id="card-modal-offer-trip" class="elevation-4">
    <v-card-title class="justify-start py-2 card-modaltitle">
      <div class="pl-4">
        <h3 class="primary--text">
          {{ $t("components.views.reports.status_offers.modal_details_offers.title") }}
        </h3>
      </div>
      <v-btn depressed text color="primary" @click.stop="$emit('closeForm')" class="cont">
        <v-icon class="mr-1">fa-solid fa-xmark</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="mb-0 px-7">
      <DataTable
        :headers="headers"
        :url="url"
        secondary
        :urlCount="urlCount"
        name="DetailsOffers"
        :height="550"
      >
        <template #[`item.needAdvance`]="{ item }">
          {{
            item.needAdvance
              ? $t("components.views.reports.status_offers.modal_details_offers.table.yes")
              : $t("components.views.reports.status_offers.modal_details_offers.table.no")
          }}
        </template>
        <template #[`item.description`]="{ item }">
          {{
            item.description === "Rejected"
              ? $t("components.views.reports.status_offers.modal_details_offers.table.rejected")
              : item.description === "Confirmed"
              ? $t("components.views.reports.status_offers.modal_details_offers.table.confirmed")
              : item.description === "Cancelled"
              ? $t("components.views.reports.status_offers.modal_details_offers.table.cancelled")
              : $t("components.views.reports.status_offers.modal_details_offers.table.counter")
          }}
        </template>
        <template #[`item.createdOn`]="{ item }">
          {{
            item.createdOn !== '' ? moment.utc(item.createdOn, "DD/MM/YYYY H:mm").local().format("DD/MM/YYYY H:mm") : ''
          }}
        </template>
        <template #[`item.updateOn`]="{ item }">
          {{
            item.updateOn !== '' ?  moment.utc(item.updateOn, "DD/MM/YYYY H:mm").local().format("DD/MM/YYYY H:mm"): ''
          }}
        </template>
        <template #[`item.amount`]="{ item }">
          {{ formatMoney(item.amount, item.currency) }}
        </template>
      </DataTable>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    idMonitoringOrder: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t(
            "components.views.reports.status_offers.modal_details_offers.table.license_plate"
          ),
          align: "center",
          class: "text-center",
          value: "licensePlate",
        },
        {
          text: this.$t(
            "components.views.reports.status_offers.modal_details_offers.table.driver_name"
          ),
          class: "text-center",
          value: "driverName",
          align: "capitalize",
        },
        {
          text: this.$t("components.views.reports.status_offers.modal_details_offers.table.value"),
          class: "text-center",
          value: "amount",
        },
        {
          text: 'rikarena',
          class: "text-center",
          value: "description",
          align: "center",
        },
        {
          text: this.$t(
            "components.views.reports.status_offers.modal_details_offers.table.hour_minutes"
          ),
          class: "text-center",
          value: "hourMinutes",
          align: "center",
        },
        {
          text: this.$t(
            "components.views.reports.status_offers.modal_details_offers.table.advance"
          ),
          class: "text-center",
          value: "needAdvance",
          align: "center",
        },
        {
          text: this.$t(
            "components.views.reports.status_offers.modal_details_offers.table.driver_phone"
          ),
          class: "text-center",
          value: "cellPhone",
        },
        {
          text: this.$t(
            "components.views.reports.status_offers.modal_details_offers.table.transporter"
          ),
          class: "text-center",
          value: "transporterCompanyName",
        },
        {
          text: this.$t(
            "components.views.reports.status_offers.modal_details_offers.table.date_create"
          ),
          class: "text-center",
          value: "createdOn",
        },
        {
          text: this.$t(
            "components.views.reports.status_offers.modal_details_offers.table.date_update"
          ),
          class: "text-center",
          value: "updateOn",
        },
      ];
    },
    url() {
      return `/Offers/RequestListAll/${this.idMonitoringOrder}`;
    },
    urlCount() {
      return `/Offers/RequestListAllCount/${this.idMonitoringOrder}`;
    },
  }
};
</script>

<style lang="scss" scoped>
#card-modal-offer-trip {
  border: 3px solid #0b0045 !important;
}

.v-divider {
  border-width: 1px !important;
}

#card-modal-offer-trip p {
  font-size: 1.17em !important;
}
.cont {
  position: absolute;
  right: 10px;
}
.card-modaltitle {
    border-bottom: 1px solid #ccc;
  }

</style>
